/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBill = /* GraphQL */ `
  mutation CreateBill($bill: BillInput!) {
    createBill(bill: $bill) {
      id
      totalInEur
    }
  }
`;
export const updateBill = /* GraphQL */ `
  mutation UpdateBill($bill: BillInput!) {
    updateBill(bill: $bill) {
      id
      totalInEur
    }
  }
`;
export const deleteBill = /* GraphQL */ `
  mutation DeleteBill($bill: BillInput!) {
    deleteBill(bill: $bill) {
      id
      totalInEur
    }
  }
`;
