import React from "react";
import logo from "./logo.svg";
import "./App.css";

import Amplify, { graphqlOperation } from "aws-amplify";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import config from "./aws-config";
import { API } from "aws-amplify";
import * as mutations from "./graphql/mutations";
import * as queries from "./graphql/queries";
import { CreateBillMutation, CreateBillMutationVariables } from "./API";

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: config.IdentityPoolId,

    // REQUIRED - Amazon Cognito Region
    region: config.region,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: config.UserPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: config.ClientId,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
  },
});

Amplify.configure({
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS", // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
  aws_appsync_region: config.region,
  aws_appsync_graphqlEndpoint: config.AppSyncEndpoint,
});

async function createBill() {
  const input: CreateBillMutationVariables = {
    bill: { id: "AAA", totalInEur: 300333 },
  };
  await API.graphql(graphqlOperation(mutations.createBill, {input}));
}

async function listAllBills() {
  const bills = await API.graphql(graphqlOperation(queries.listBills));
  console.log({ bills });
}

function App() {
  return (
    <div className="App">
      <AmplifySignOut />
      <button onClick={() => listAllBills()}>List</button>
      <button onClick={() => createBill()}>Create Bill</button>
    </div>
  );
}

export default withAuthenticator(App);
